.application-Holder{
    width: 600px;
    margin: 0 auto;
   
  }
 
  
  /*inputfeild*/

  .form-feilds-container{
    display: flex;
    z-index: 5;
    margin-top: -100px;
    background: white ;
    border-radius: 3px;
    padding: 10px;
   
  }
  input{
    border: none;
    width: 90%;
    margin-left: 8px;
    font-size: 13px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
  }
  input:focus{
    outline: none;
    }


/*todo list items*/
.task-list-container{
  background: white ;
  border-radius: 3px;
  padding-bottom: 30px;
  box-shadow: 0 0 7px #a09e9e;
  margin-top: 30px;
}

.task{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.301);
  cursor: pointer;
}

h2{
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  margin-left: 10px;
  
}
.error{
  color: red;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 10px;
}
.selecteIcon-text{
align-items: center;
display: flex;

}
.icon{
color: hsl(234, 11%, 52%);  
}
button{
margin-top: 30px;
border: none;
font-family: 'Josefin Sans', sans-serif;
font-weight: 700;
font-size: 10px;
background: gold;
color: black;
cursor: pointer;
padding: 7px;
border-radius: 6px;
}
.notes-for-use{
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}
p{
font-family: 'Josefin Sans', sans-serif;
font-weight: 400;
font-size: 12px;
color: hsl(235, 19%, 35%);
}
span{
  background: #a09e9e;
  padding: 4px;
  color: black;
  border-radius: 6px;
}

/*mobile devices styles*/
@media only screen and (max-width: 475px) {

  .application-Holder{
    width: 100%;
   
  }
  .mobile-divice-container{
    padding: 10px;
  }

  .notes-for-use{
    display: flex;
    flex-direction: column;
   justify-content: center;
    align-items: center;
  }

}